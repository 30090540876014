import "./Preview.scss";



export const Preview = () => {
    return(
        <div className="Preview">
            <h1 className="Preview-title">Выбери свой цетиризин</h1>
        </div>
    )
}

